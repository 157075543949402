import Swiper from 'swiper/bundle';

import 'swiper/css';
import 'swiper/css/pagination';

export default class Banner {
    constructor() {
        this.init();
    }

    init() {
        this.setSwiper();
    }

    setSwiper() {
        const slides = [...document.querySelectorAll('.mainvisual__bnr .swiper-slide')];
        
        const option = {
            autoplay: slides.length > 1 ? { delay: 5000, disableOnInteraction: false } : false,
            loop: true,
            speed: 700,
            pagination: { el: '.swiper-pagination', clickable: true },
        };

        const swiper = new Swiper('.js-mvbanner-swiper', option);
    }
}
