import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default class Intro {
    constructor() {
        this.init();
    }

    init() {
        this.kvLoad(); //キービジュ先読み
        this.introSkip();
        this.showHeader();
    }

    kvLoad() {
        const imgs = ['/assets/img/top/mainvisual/mv.jpg', '/assets/img/top/mainvisual/mv_nrw.jpg'];
        imgs.forEach((el) => {
            const img = new Image();
            img.src = window.innerWidth <= 768 ? el.replace('mv.', 'mv_nrw.') : el;
        });
    }

    introIn() {
        setTimeout(() => {
            document.body.classList.add('-introend');
            STORAGE.data.intro && STORAGE.set('intro', false);
        }, 4500);
    }

    introSkip() {
        const url = new URL(location);
        const params = url.searchParams;

        //console.log(STORAGE.data.intro);

        if (STORAGE.data.intro) {
            this.introIn();
        } else if (params.get('skip') || !STORAGE.data.intro) {
            document.body.classList.add('-introend');
        }
    }

    showHeader() {
        const triggerElm = document.querySelector('.js-navin');

        ScrollTrigger.create({
            trigger: triggerElm,
            start: 'top top',
            end: 'bottom top',

            onLeave: () => {
                document.body.classList.add('-showHeader');
            },
            onEnterBack: () => {
                document.body.classList.remove('-showHeader');
            },
            once: false,
            //markers: true,
        });
    }
}
